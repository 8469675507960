import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import gql from "graphql-tag"
import { useLazyQuery } from "@apollo/react-hooks"
import { Helmet } from "react-helmet"
import { withFormik, Form, Field } from "formik"
import * as Yup from "yup"
import NumberFormat from "react-number-format"
import { graphql, navigate, useStaticQuery } from "gatsby"
import { useMediaPredicate } from "react-media-hook"
import Select from "react-select"
import Modal from "react-modal/lib/components/Modal"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import { Section, Container } from "../../components/grid/grid"
import extraKetupatBackground from "../../assets/img/extraketupat/ek-desktop-bg.jpg"
import backsound from "../../assets/audio/Extra-Ketupat-Website-Backsound.mp3"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const GET_PHONENUMBER = gql`
  query formExtraKetupat {
    formExtraKetupat {
      project
      phoneNumber
    }
  }
`

const ExtraKetupatPage = (location) =>{
  const staticData = useStaticQuery(graphql`
  query{
    wordPress {
      projects(first: 1000) {
        nodes {
          title
          slug
          status
          locations {
            nodes {
              name
              slug
            }
          }
        }
      }
    }
  }
`)

  const [getResult, { data }] = useLazyQuery(GET_PHONENUMBER)
  const [soundModal, setSoundModal] = useState(false)
  const resizeScreen = useMediaPredicate("(max-width: 992px)")
  const ogUrl = process.env.BASE_URL + location.pathname + "/"

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  }

  // let listOfProduct = []
  // const projectList = staticData?.wordPress?.projects

  // projectList?.nodes.map(item => {
  //   if(item?.status === "publish"){
  //     listOfProduct.push({
  //       label: `${item?.title}, ${item?.locations?.nodes[0]?.name}`,
  //       value: `${item?.title}, ${item?.locations?.nodes[0]?.name}`,
  //     })
  //   }
  // })

  let listOfProduct = [
    { label: 'Acropolis, Cibubur' , value: 'Acropolis, Cibubur'},
    { label: 'Aerium, Jakarta' , value: 'Aerium, Jakarta'},
    { label: 'Aether, BSD City' , value: 'Aether, BSD City'},
    { label: 'Alegria Park, BSD City' , value: 'Alegria Park, BSD City'},
    { label: 'Alexandria, Surabaya' , value: 'Alexandria, Surabaya'},
    { label: 'AMARINE, BSD City' , value: 'AMARINE, BSD City'},
    { label: 'Amata, BSD City' , value: 'Amata, BSD City'},
    { label: 'Anila House, BSD City' , value: 'Anila House, BSD City'},
    { label: 'Assana House, BSD City' , value: 'Assana House, BSD City'},
    { label: 'Autoparts, BSD City' , value: 'Autoparts, BSD City'},
    { label: 'Azura House, BSD City' , value: 'Azura House, BSD City'},
    { label: 'Azure, Klaska Residence, Surabaya' , value: 'Azure, Klaska Residence, Surabaya'},
    { label: 'Blossom Ville Ex, BSD City' , value: 'Blossom Ville Ex, BSD City'},
    { label: 'Breezy House, BSD City' , value: 'Breezy House, BSD City'},
    { label: 'Carribean Island, BSD City' , value: 'Carribean Island, BSD City'},
    { label: 'Casa De Parco - Cassea, BSD City' , value: 'Casa De Parco - Cassea, BSD City'},
    { label: 'Casa De Parco - Gardenia, BSD City' , value: 'Casa De Parco - Gardenia, BSD City'},
    { label: 'Casa De Parco - Magnolia, BSD City' , value: 'Casa De Parco - Magnolia, BSD City'},
    { label: 'Cattleya, BSD City' , value: 'Cattleya, BSD City'},
    { label: 'Cheville, Balik Papan' , value: 'Cheville, Balik Papan'},
    { label: 'Cloverville Kavling, Surabaya' , value: 'Cloverville Kavling, Surabaya'},
    { label: 'Cluster Alesha, BSD City' , value: 'Cluster Alesha, BSD City'},
    { label: 'Cluster Anarta , BSD City' , value: 'Cluster Anarta , BSD City'},
    { label: 'Cluster Asatti, BSD City' , value: 'Cluster Asatti, BSD City'},
    { label: 'Cluster Azura Starlight, BSD City' , value: 'Cluster Azura Starlight, BSD City'},
    { label: 'Cluster Caelus, BSD City' , value: 'Cluster Caelus, BSD City'},
    { label: 'Cluster Jadeite, BSD City' , value: 'Cluster Jadeite, BSD City'},
    { label: 'Cluster Whelford, BSD City' , value: 'Cluster Whelford, BSD City'},
    { label: 'Cluster Whitsand II , BSD City' , value: 'Cluster Whitsand II , BSD City'},
    { label: 'Costa Rica, BSD City' , value: 'Costa Rica, BSD City'},
    { label: 'Cozmo House, BSD City' , value: 'Cozmo House, BSD City'},
    { label: 'Cozmo House Ext, BSD City' , value: 'Cozmo House Ext, BSD City'},
    { label: 'Da Vinci, Cibubur' , value: 'Da Vinci, Cibubur'},
    { label: 'DE MAJA, BSD City' , value: 'DE MAJA, BSD City'},
    { label: 'Element Tower 1, Jakarta' , value: 'Element Tower 1, Jakarta'},
    { label: 'Element Tower 2, Jakarta' , value: 'Element Tower 2, Jakarta'},
    { label: 'FLATHOUSE, BSD City' , value: 'FLATHOUSE, BSD City'},
    { label: 'Fleekhauz , BSD City' , value: 'Fleekhauz , BSD City'},
    { label: 'Fleekhauz R, BSD City' , value: 'Fleekhauz R, BSD City'},
    { label: 'FOGLIO, BSD City' , value: 'FOGLIO, BSD City'},
    { label: 'Freja Suites, BSD City' , value: 'Freja Suites, BSD City'},
    { label: 'Garden Suite, Surabaya' , value: 'Garden Suite, Surabaya'},
    { label: 'GIARDINA, BSD City' , value: 'GIARDINA, BSD City'},
    { label: 'Giri Loka, BSD City' , value: 'Giri Loka, BSD City'},
    { label: 'Golden Boulevard, Balik Papan' , value: 'Golden Boulevard, Balik Papan'},
    { label: 'Golden Viena, BSD City' , value: 'Golden Viena, BSD City'},
    { label: 'Grand Palais, Surabaya' , value: 'Grand Palais, Surabaya'},
    { label: 'HAYFIELD, Balik Papan' , value: 'HAYFIELD, Balik Papan'},
    { label: 'Hayfield Thp 3B, Balik Papan' , value: 'Hayfield Thp 3B, Balik Papan'},
    { label: 'HYLAND, Balik Papan' , value: 'HYLAND, Balik Papan'},
    { label: 'HYLANDS, BSD City' , value: 'HYLANDS, BSD City'},
    { label: 'IMAJIHAUS, BSD City' , value: 'IMAJIHAUS, BSD City'},
    { label: 'Ingenia, BSD City' , value: 'Ingenia, BSD City'},
    { label: 'Inspirahaus, BSD City' , value: 'Inspirahaus, BSD City'},
    { label: 'Invensihaus R, BSD City' , value: 'Invensihaus R, BSD City'},
    { label: 'Kavling Phase 1, Batam' , value: 'Kavling Phase 1, Batam'},
    { label: 'Kavling Phase 2, Batam' , value: 'Kavling Phase 2, Batam'},
    { label: 'Kios Pasar Modern, BSD City' , value: 'Kios Pasar Modern, BSD City'},
    { label: 'Kios Pasar Modern 2/ Barat, BSD City' , value: 'Kios Pasar Modern 2/ Barat, BSD City'},
    { label: 'Landed Phase 1 (Blok ABCD), Batam' , value: 'Landed Phase 1 (Blok ABCD), Batam'},
    { label: 'Landed Phase 2 (Blok EFGH), Batam' , value: 'Landed Phase 2 (Blok EFGH), Batam'},
    { label: 'Latinos Business Dis Thp 1, BSD City' , value: 'Latinos Business Dis Thp 1, BSD City'},
    { label: 'Latinos Business Dis Thp 2, BSD City' , value: 'Latinos Business Dis Thp 2, BSD City'},
    { label: 'Lumibizz, Cibubur' , value: 'Lumibizz, Cibubur'},
    { label: 'Lumihous, Cibubur' , value: 'Lumihous, Cibubur'},
    { label: 'Mississippi, Cibubur' , value: 'Mississippi, Cibubur'},
    { label: 'New Palladium, Balik Papan' , value: 'New Palladium, Balik Papan'},
    { label: 'Paddington, Surabaya' , value: 'Paddington, Surabaya'},
    { label: 'Palais de Luxembourg, Surabaya' , value: 'Palais de Luxembourg, Surabaya'},
    { label: 'Pineville, Balik Papan' , value: 'Pineville, Balik Papan'},
    { label: 'Plot A, BSD City' , value: 'Plot A, BSD City'},
    { label: 'Precia, BSD City' , value: 'Precia, BSD City'},
    { label: 'Quantis, BSD City' , value: 'Quantis, BSD City'},
    { label: 'Ruko A6, Balik Papan' , value: 'Ruko A6, Balik Papan'},
    { label: 'Ruko ICE Business Park Tahap 2, BSD City' , value: 'Ruko ICE Business Park Tahap 2, BSD City'},
    { label: 'Ruko Madison, Cibubur' , value: 'Ruko Madison, Cibubur'},
    { label: 'Ruko Madrid X, BSD City' , value: 'Ruko Madrid X, BSD City'},
    { label: 'Ruko Pro. Suites, BSD City' , value: 'Ruko Pro. Suites, BSD City'},
    { label: 'Ruko The Savia, BSD City' , value: 'Ruko The Savia, BSD City'},
    { label: 'Rumah Zena, BSD City' , value: 'Rumah Zena, BSD City'},
    { label: 'Savia Park, BSD City' , value: 'Savia Park, BSD City'},
    { label: 'Southgate Altuerra, Jakarta' , value: 'Southgate Altuerra, Jakarta'},
    { label: 'Southgate Elegance, Jakarta' , value: 'Southgate Elegance, Jakarta'},
    { label: 'Southgate Prime, Jakarta' , value: 'Southgate Prime, Jakarta'},
    { label: 'Street of London, Surabaya' , value: 'Street of London, Surabaya'},
    { label: 'Street of Paris, Surabaya' , value: 'Street of Paris, Surabaya'},
    { label: 'The Blizfield, BSD City' , value: 'The Blizfield, BSD City'},
    { label: 'Tower Kaina, Batam' , value: 'Tower Kaina, Batam'},
    { label: 'Tower Kalani, Batam' , value: 'Tower Kalani, Batam'},
    { label: 'Virginia Arcade, BSD City' , value: 'Virginia Arcade, BSD City'},
    { label: 'Visana at The Savia, BSD City' , value: 'Visana at The Savia, BSD City'},
    { label: 'VIVACIA, BSD City' , value: 'VIVACIA, BSD City'},
    { label: 'West Park, BSD City' , value: 'West Park, BSD City'},
    { label: 'Yuthica, BSD City' , value: 'Yuthica, BSD City'},
  ]

  const handlePlaySound = () => {
    let audioBack = new Audio(backsound)
    audioBack.play()
    setSoundModal(false)
  }

  const LeadForm = ({ errors, touched, values, setFieldValue, btnLoading }) => {
    const phoneNumberHandler = (val, setFieldValue) =>
      setFieldValue("phoneNumber", val)
    const [checked, setChecked] = useState(false)
  
    function handleOnChange(){
      setChecked(!checked)
    }
  
    return (
      <Form className="row pt-4" id="surpriseForm">
        <div className="col-12 col-md-12 pb-3">
          <label className="text-muted my-0">Nama</label>
          <Field
            name="name"
            className="form-control px-0"
            placeholder="Nama Anda"
            type="text"
            id="customerName"
          />
          {touched.name && errors.name && (
            <div className="invalid-value d-block">{errors.name}</div>
          )}
        </div>
        <div className="col-12 col-md-12 pb-3">
          <label className="text-muted my-0">Nomor Handphone</label>
          <NumberFormat
            name="phoneNumber"
            className="form-control px-0"
            placeholder="Nomor Handphone"
            allowEmptyFormatting
            prefix="+62"
            value={values.phoneNumber}
            onValueChange={(val) =>
              phoneNumberHandler(val.formattedValue, setFieldValue)
            }
            id="customerPhone"
          />
          {touched.phoneNumber && errors.phoneNumber && (
            <div className="invalid-value d-block">{errors.phoneNumber}</div>
          )}
        </div>
        <div className="col-12 col-md-12 pb-3">
          <label className="text-muted my-0">Email</label>
          <Field
            name="email"
            className="form-control px-0"
            placeholder="Email Anda"
            type="text"
            id="customerEmail"
          />
          {touched.email && errors.email && (
            <div className="invalid-value d-block">{errors.email}</div>
          )}
        </div>
        <div className="col-12 col-md-12 pb-3">
          <label className="text-muted my-0">Produk Yang Dibeli</label>
          <Select
            className="form-control px-0"
            classNamePrefix="Produk Yang Dibeli"
            options={listOfProduct}
            defaultValue={{ value: "", label: "Produk Yang Dibeli" }}
            id="product"
            onChange={(e) => {
              setFieldValue("product", e.value)
              sessionStorage.setItem("product", e.value)
            }}
          />
          {touched.product && errors.product && (
            <div className="invalid-value d-block">{errors.product}</div>
          )}
        </div>
        <div className="col-12 col-md-12 pb-3">
          <label className="text-muted my-0">Nama Sales</label>
          <Field
            name="salesName"
            className="form-control px-0"
            placeholder="Nama sales"
            type="text"
            id="salesName"
          />
          {touched.salesName && errors.salesName && (
            <div className="invalid-value d-block">{errors.salesName}</div>
          )}
        </div>
        <div className="col-12 col-md-12 pb-3">
          <label className="text-muted my-0">
            <Field 
              type="checkbox" 
              name="checked" 
              value="Agent" 
              onClick={handleOnChange}
            />
            &nbsp;Agent
          </label>
        </div>
        <div className={checked ? "col-12 col-md-12 pb-3 d-block" : "col-12 col-md-12 pb-3 d-none"}>
          <label className="text-muted my-0">Nama Kantor</label>
          <Field
            name="officeName"
            className="form-control px-0"
            placeholder="Nama Kantor"
            type="text"
            id="officeName"
          />
          {touched.officeName && errors.officeName && (
            <div className="invalid-value d-block">{errors.officeName}</div>
          )}
        </div>
        <div className="col-12 col-md-12 pb-3 pt-3" id="disclaimer">
          <label className="text-muted my-0">
            <Field 
              type="checkbox" 
              name="isAgree" 
              value="isAgree" 
              id="isAgree"
            />
            &nbsp;Saya menyetujui bahwa keputusan Extra Ketupat ini tidak dapat diganggu gugat.
          </label>
          {touched.isAgree && errors.isAgree && (
            <div className="invalid-value d-block">{errors.isAgree}</div>
          )}
        </div>
        <div className="col-12 pt-5">
          <button className="btn btn-primary w-100 w-md-25">
            {btnLoading ? <i className="fa fa-spinner fa-spin"></i> : "Submit"}
          </button>
        </div>
      </Form>
    )
  }
  
  useEffect(() => {
    if (location.uri == "/extraketupat") {
      let navbarDesktop = document.getElementById("desktopNav").querySelectorAll(".nav-link")
      
      if(resizeScreen){
        document.getElementsByClassName("logo-secondary")[1].style.display = `none`
        document.getElementsByClassName("logo-main")[1].style.display = `block`
      }else{
        document.getElementsByClassName("logo-secondary")[0].style.display = `none`
        document.getElementsByClassName("logo-main")[0].style.display = `block`
      }

      for (let i = 0; i < navbarDesktop.length; i++) {
        navbarDesktop[i].style.color = "#000"
      }
      document.querySelector("#lang-wrapper").classList.remove('d-flex')
      document.querySelector("#lang-wrapper").classList.add('d-none')
    }
  }, [])

  useEffect(() => {
    getResult()
  }, [])

  useEffect(() => {
    setTimeout(function(){setSoundModal(true)}, 200)
  }, [])

  const [formValues, setFormValues] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    product: "",
    salesName: "",
    isAgree: "",
    officeName: "",
  })

  const EnhancedLeadForm = withFormik({
    mapPropsToValues({ name, phoneNumber, email, product, salesName, isAgree, officeName}) {
      return {
        name: formValues.name || name,
        email: formValues.email || email,
        phoneNumber: formValues.phoneNumber || phoneNumber,
        product: formValues.product || product,
        salesName: formValues.salesName || salesName,
        isAgree: formValues.isAgree || isAgree,
        officeName: formValues.officeName || officeName,
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      email: Yup.string().required("Email is required"),
      phoneNumber: Yup.string().required("Phone number is required"),
      product: Yup.string().required("Produk Yang Dibeli is required"),
      salesName: Yup.string().required("Nama sales is required"),
      isAgree: Yup.string().required("Disclaimer is required"),
    }),
    handleSubmit(values) {
      setFormValues(values)

      let dataList = data.formExtraKetupat
      let counter = 0

      for (let i = 0; i < dataList.length; i++) {

        if (dataList[i].phoneNumber == values.phoneNumber.split("+").join("")) {
          console.log(">>1")
          alert("Maaf! Anda tidak bisa mengikuti program Extra Ketupat kembali")
          return false
          // if(dataList[i].project.trim() == values.product.trim()){
          //   console.log(">>2")
          //   alert("Maaf! Anda tidak bisa mengikuti program Extra Ketupat kembali")
          //   return false
          // }else{
          //   console.log(">>3")
          //   counter = counter + 1
          // }
        }

        // if(counter == 2){
        //   console.log(">>4")
        //   alert("Maaf! Anda tidak bisa mengikuti program Extra Ketupat kembali")
        //   return false
        // }
      }

        console.log(">>5")
        let form = document.getElementById("surpriseForm")
        for (let q = 0; q < form.elements.length; q++) {
          let eSecond = form.elements[q]
          if (eSecond.type === "button") continue
          let idSecond = eSecond.id
          var valueSecond = eSecond.value
          sessionStorage.setItem(idSecond, valueSecond)
        }
        navigate("/extraketupat/pop")

    },
  })(LeadForm)

  useEffect(() => {
    if (resizeScreen) {
      document.querySelector("#extraKetupatSection").style.background = `url('${extraKetupatBackground}') no-repeat`
      document.querySelector("#extraKetupatSection").style.backgroundSize = `cover`
    } else {
      document.querySelector("#extraKetupatSection").style.background = `url('${extraKetupatBackground}') no-repeat`
      document.querySelector("#extraKetupatSection").style.backgroundSize = `contain`
    }
  }, [])

  return(
    <Layout location={location} currentLocation={location.uri}>
      <SEO 
        title={`Extra Ketupat Lebaran 2023`}
        fbTitle={`Extra Ketupat Lebaran 2023`}
        twitterTitle={`Extra Ketupat Lebaran 2023`}
        description={`Sinar Mas Land memberikan program spesial bernama Extra Ketupat Lebaran. Produk yang berpartisipasi dalam Ketupat Lebaran mencakup semua properti Sinar Mas Land. `}
        fbDescription={`Sinar Mas Land memberikan program spesial bernama Extra Ketupat Lebaran. Produk yang berpartisipasi dalam Ketupat Lebaran mencakup semua properti Sinar Mas Land.`}
        twitterDescription={`Sinar Mas Land memberikan program spesial bernama Extra Ketupat Lebaran. Produk yang berpartisipasi dalam Ketupat Lebaran mencakup semua properti Sinar Mas Land.`}
        url={ogUrl}
        img={`https://www.sinarmasland.com/app/uploads/2023/03/extraketupat.jpg`}
        fbImg={`https://www.sinarmasland.com/app/uploads/2023/03/extraketupat.jpg`}
        twitterImg={`https://www.sinarmasland.com/app/uploads/2023/03/extraketupat.jpg`}
      />
      <Helmet>
        <meta 
          name="Permissions-Policy" 
          content="autoplay=(self)"
        ></meta>
        <body className="bd-page" />
        <meta 
          name="keywords" 
          content="extra ketupat 2023, diskon properti sinar mas land, program extra ketupat, "
        ></meta>
        <meta 
          name="copyright" 
          content="2023 | Sinar Mas Land"
        ></meta>
      </Helmet>
      <Section className="mainSurpriseKetupat bg-gray-50" id="extraKetupatSection">
        <Container className="surpriseForm">
          <EnhancedLeadForm />
        </Container>
      </Section>
      <Modal
        isOpen={soundModal}
        onRequestClose={handlePlaySound}
        style={customStyles}
        contentLabel="campaign modal"
      >
        <div className="modal-popup-audio modal-body text-center">
          <div className="container py-5 mw-100 mw-md-75">
            <p className="m-0">
              Izinkan untuk memutar audio
            </p>
              <button
                className="btn btn-ecatalog-theme mt-4"
                onClick={handlePlaySound}
              >
                Ok
              </button>
          </div>
        </div>
      </Modal>
    </Layout>
  )
}
export default ExtraKetupatPage
